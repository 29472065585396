

import { Inject } from 'inversify-props';
import { Component, Vue } from 'vue-property-decorator';
import moment from 'moment';
import COMPARE_TO from '@/modules/deep-analysis/constants/compare-to-filter.constant';
import DeepCompsetAnalysisClusterService, { DeepCompsetAnalysisClusterServiceS } from '../../cluster-deep-analysis.service';
import ClusterService, { ClusterServiceS } from '../../cluster.service';
import SkeletonLine from '../_common/skeleton-line.vue';

@Component({ components: { SkeletonLine } })
export default class DeepAnalysisClusterInfo extends Vue {
    @Inject(DeepCompsetAnalysisClusterServiceS) dcaClusterService!: DeepCompsetAnalysisClusterService;
    @Inject(ClusterServiceS) clusterService!: ClusterService;

    get text() {
        const isBook = this.dcaClusterService.showBy.startsWith('book');
        const days = this.dcaClusterService.showBy.split('.')[1];
        return isBook
            ? this.$t('deepcomp.cal.lastmsg', [days]).toString()
            : this.$t('deepcomp.cal.nextmsg', [days]).toString();
    }

    get comparedValueName() {
        const { compareTo } = this.dcaClusterService;
        return compareTo === COMPARE_TO.COMPSET_AVG ? this.$tc('filters.deepcomp.avg') : this.$tc('filters.deepcomp.last');
    }

    get date() {
        return moment(this.dcaClusterService.updatedAt).format('MMM, D YYYY');
    }

    get isLoading() {
        return this.clusterService.isLoading;
    }

    get isSearchQuery() {
        return !!this.clusterService.clusterHotelSearchQuery;
    }
}

