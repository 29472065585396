

import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import DeepCompsetAnalysisClusterService, { DeepCompsetAnalysisClusterServiceS } from '../../../cluster-deep-analysis.service';
import { DEEP_ANALYSIS_PROVIDERS } from '../../../constants';

@Component({
    components: { CustomSelect },
})
export default class ProviderFilter extends Vue {
    @Inject(DeepCompsetAnalysisClusterServiceS) deepCompsetAnalysisClusterService!: DeepCompsetAnalysisClusterService;
    @Inject(ProvidersServiceS) private providersService!: ProvidersService;

    get value() {
        return this.deepCompsetAnalysisClusterService.provider;
    }

    set value(value: typeof DEEP_ANALYSIS_PROVIDERS[number] | null) {
        this.deepCompsetAnalysisClusterService.provider = value || DEEP_ANALYSIS_PROVIDERS[0];
    }

    get items(): Item[] {
        return DEEP_ANALYSIS_PROVIDERS.map((value): Item => ({
            value,
            name: this.getProviderLabel(value),
            disabled: false,
        }));
    }

    getProviderLabel(providerName: string) {
        return this.providersService.getProviderLabel(providerName);
    }
}
